.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    scroll-behavior: smooth;
    z-index: 2;
  }

  .popup_inner {
    position: fixed;
    left: 10%;
    right: 10%;
    top: 10%;
    bottom: 10%;
    margin: auto;
    border-radius: 20px;
    background: rgba(92, 104, 114, 0.945);
    /* background: rgba(157, 171, 182, 0.911); */
    overflow-y: scroll;
    overflow-x: clip;
    scrollbar-color: rgba(65,68,85,.945) rgba(92,104,114,.945);
    scrollbar-width: thin;
  }

  .popup_exit_button {
    margin-left: 98%;
  }