.App {
  text-align: center;
}

.ConstellationBackground {
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
}

.TutorialBackground {
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
  /* background: radial-gradient(circle at right 2% top 10%, rgba(196, 189, 166, 0.884) .15%, #bebebee0 2.5%, rgba(255, 255, 255, 0.432) 7%, rgba(7, 38, 95, 0.6) 35%, rgba(0, 1, 3, 0.863) 100%),
    radial-gradient(circle at left 15% bottom 12%, rgba(0, 0, 0, 0.966) 10%, rgba(12, 12, 12, 0.925) 25%, rgba(8, 8, 8, 0.541) 55%, rgba(1, 8, 20, 0.6) 100%); */
}
/* .LandingPageStyle{

  text-align: center;
  background: rgb(0, 0, 0) 100%;
 
  height: 100vh;
} */

.NavigateToAlpacaPrompt {
  position: fixed;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%;
  margin: auto;
}

.UserPromptStyle {
  position: fixed;
  left: 4.5%;
  right: 4.5%;
  top: 4.5%;
  bottom: 4.5%;
  margin: auto;
}

.AsyncConfirmMessage{
  font-size: large;
}

.AsyncConfirmButtonRow{
  background-color: #0f0f19a7;
  border:1px solid;
  color: #dfdfdf;
  border-radius: 20px;
  z-index: 2;  
  margin: 10px;
  display: inline-block
}

.AsyncConfirmButton{
  display: inline-block;
  margin-top: 4vh;
  margin-right: 2vw;  
}

.UserProfilePromptMessage{
  font-size: large;
}

.UserProfilePromptButtons{
  margin-top: 4vh;
  margin-right: 2vw;  
  display: inline-block;
}

.UserProfilePrompt{
  background-color: #1913305f;
  backdrop-filter: blur(5px);
  border:1px solid;
  color: #dfdfdf;
  border-radius: 20px;
  z-index: 2;  
  margin: 10px;
  display: inline-block;
  width: 90vw;
  height: 90vh;
}

.UserGridPrompt{
  min-width: 200px;
}

/* .UserPromptText{
  position: relative;
  display: inline-block;
} */
/* .UserPromptSimulaterow
{
  display: inline-flex;
} */

.UserPromptSimulateButtonRow
{
  position: relative;
  left: 5%;
}

body {
  position: relative;
  background: rgba(2, 29, 78, 0.623);
  overflow-y: hidden;
}

.StrategyConfigurationPrompt {
  position: fixed;
  left: 35%;
  right: 35%;
  top: 10%;
  margin: auto;
  background: rgba(3, 16, 39, 0.959);
  overflow-y: hidden;
}

.StrategyConfigurationPromptText{
  color: white;
  z-index: 10;
}


.MuiInputBase-input {
  margin-right: 16px !important;
  }

.StrategyConfigurationPromptTextTitle{
  font-weight: bold;
  font-size: x-large;
  margin-bottom: 20px;
  margin-top: 20px;
}

.ConfigureStrategyInputFields{
  position: relative;
}

.StartDateDiv
{
  display: block;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.NeuronsView{
  background: rgba(2, 29, 78, 0.795);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiButton-label{
  background-color: rgba(241, 241, 241, 0.705);
  margin: 10px;
  border-radius: 5px;
  border:1px solid;
  border-color: rgb(34, 68, 21);
}

.MuiFormControl-root {
  background-color: rgba(241, 241, 241, 0.705);
  display: block;
}

.MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl{
  display: block;
}

.MuiInputBase-input MuiInput-input{
  display: block;
}

.StockResultData {
  display: inline-block;
  position: relative;
  left: calc(10px + 2vmin);
  margin: 10px;
}

.OptionsRow{
  position: relative;
  margin-bottom: 5%;
  margin-left: 20px;
}

.AlgorithmChart {
  margin-bottom: 1%;
  background-color: rgba(216, 216, 216, 0.705);
}


.LineGraph {
  /* background-color: rgba(216, 216, 216, 0.705); */
  margin-bottom: 1%;
  width: 3200px;
  height: 35vh;
  overflow-y: visible;
  direction: ltr;
  position: relative;
  /* position: relative; */
  /* background-color: rgba(216, 216, 216, 0.705); */
}

.UserPromptLineGraph {
  /* background-color: rgba(216, 216, 216, 0.705); */
  margin-bottom: 1%;
  height: 35vh;
  overflow-y: visible;
  /* position: relative; */
  /* background-color: rgba(216, 216, 216, 0.705); */
}

/* .LineGraph > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
} */

.FullLineChart {
  width: 1200;
  overflow-x: scroll;
  direction: rtl;
  scrollbar-width: thin;
}



.SummaryProfitPanel{
  position: relative;
  display:flex; 
  flex-direction:row;
}

.ReportProfitablePanel {
  background-color: #2d5235e8;
  border:2px solid;
  color: #dfdfdf;
  border-radius: 20px;
  float: left;
  z-index: 2;  
  margin: 10px;
}

.ReportNonProfitablePanel
{
  background-color: #693535bd;
  border:2px solid;
  color: #dfdfdf;
  border-radius: 20px;
  float: left;
  z-index: 2;
  margin: 10px
}

.TopMenuBar
{
  background-color: rgba(255, 255, 255, 0.775);
  /* background-color: rgba(106, 106, 119, 0.267); */
  border:1px solid;
  color: #645b5b;
  border-color: #857e7e;
  border-radius: 10px; 
  position: absolute;
  width: 100%;
  z-index:10;
  opacity: 0;
}

.LoginComponent
{
  margin-left: 1%;
}

.FundsComponent
{
  margin-left: 2%;
}

.RollingTextBar
{
  margin-right: 2%;
  margin-top: 2vh;
}

.GoodNewsUpdate
{
  color: rgb(0, 119, 0);
}

.BadNewsUpdate
{
  color: rgb(90, 0, 0);
}

.PanelCardHeader
{
  border-bottom: honeydew;
}

.MainNavMenuBar
{
  background-color: #61dafb;
}

.ToggleSidebarController
{
  position: absolute;
  top: 60px;
  left: 0px;
  z-index: 20;
  opacity: 0;
}

.NeuronControlPanel
{
  position: absolute;
  top: 55px;
}

.ui.sidebar
{
  position: relative;
}

.TickerCheckBoxCell
{
  background: #857e7e;
}

.TickerNameCell
{
  background: #857e7e;
}

.TickerRatingCell
{
  background: #857e7e;
}

.conditionals-dropdown-content {
  /* position: absolute; */
  /* background-color: #f9f9f9; */
  min-width: 200px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
  padding: 2px 6px; */
  border-radius: 3rem;
  z-index: 1;
  margin-bottom: 5px;
  overflow: visible;
}

.InputWithDataSet{
  min-width: 200px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
  padding: 2px 6px; */
  /* border-radius: 3rem; */
  z-index: 1;
}

.FilteredTableView{  
  height: 700px;
  overflow-y: scroll;
  
}

.UserStrategyTable
{
  max-height: 45vh;
  overflow-y: scroll;
}

/* .UserStrategyTableInner{
   overflow-y: scroll !important; 
} */

/* .UserStrategyTableInner2{
  overflow-y: scroll !important;
} */

.UserPromptLineChart{
  overflow-y: scroll;

}

/* .SearchMenuBar{
  margin-top: 1em;
}

.FundsComponent{
  margin-top: 1em;
}

.LoginComponent{
  margin-top: 1em;
}

.HomeMenuBarOptions{
  margin-top: 1em;
}

.MyStrategyMenuBarOptions{
  margin-top: 1em;
}

.TopPerformersMenuBarOptions{
  margin-top: 1em;
}

.TutorialMenuBarOptions{
  margin-top: 1em;
}

.BrandMenuTitle{
  margin-top: 1em;
} */

#canvas {
  display: inline-block;
}


/* body::after {
  z-index: 10;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: '';
  background-image: radial-gradient( rgb(255, 255, 255, .3) 10%, rgba(2, 29, 78, 0.795) 100%);
} */

