.TutorialButton {
    cursor: pointer;
    background-color: #000;
    line-height: 64px;
    /* position: relative; */
    /* transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%); */
    z-index: 0;
    background:#9A8A62;
    /* margin-left:20px; */
    font-size: 2vw;  
    width: 360px;
    height: 200px;
    position: absolute;
    left: 40%;
    right: 10%;
    top: 100%;
    bottom: 10%;
    opacity: .4;
  }
  .TutorialButton span1 {
    color: #fff;
    display: inline-block;
    text-transform: uppercase;
    font: bold 18px/66px Arial;
    transform: scaleX(0.6);
    letter-spacing: 3px;
    transition: all 1.3s ease;
    position: absolute;
    bottom: 0;
    right: -3%;
    z-index: 1;
  }
  .TutorialButton span2 {
    color: #fff;
    display: inline-block;
    padding-left: 8px;
    text-transform: uppercase;
    font: 32px/66px Arial;
    transform: scaleX(0.6);
    /* transform: scaleY(1.2); */
    letter-spacing: 3px;
    transform-origin: center left;
    transition: all 1.3s ease;
    position: relative;
    z-index: 1;
    padding-top: 17px;
    font-size: 4vw;
  }
  .TutorialButton em {
    position: absolute;
    height: 1px;
    background: #fff;
    width: 47%;
    right: 23px;
    bottom: 25%;
    transform: scaleX(0.25);
     -webkit-transform:scaleX(0.25);
    transform-origin: center right;
    transition: all 1.3s ease;
    z-index: 1;
  }
  .TutorialButton:before,
  .TutorialButton:after {
    content: '';
    background: #fff;
    height: 50%;
    width: 0;
    position: absolute;
    transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transition:0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  .TutorialButton:before {
    top: 0;
    left: 0;
    right: auto;
  }
  .TutorialButton:after {
    bottom: 0;
    right: 0;
    left: auto;
  }
  .TutorialButton:hover{
    opacity: 1;
  }
  .TutorialButton:hover:before {
    width: 100%;
    right: 0;
    left: auto;
  }
  .TutorialButton:hover:after {
    width: 100%;
    left: 0;
    right: auto;
  }
  .TutorialButton:hover span1 {
    color: #000;
    transform: translate(-60px, -15px);
    font: bold 24px/66px Arial;
    /* padding-left: 5px; */
  }
  .TutorialButton:hover span2 {
    color: #000;
    transform: scale(.55, .8);
  }
  .TutorialButton:hover em {
    background: #000;
    transform: scaleX(1);
    right: 50%;
    bottom: 35%;
  }

  .LandingPageButton { 
    padding: 9px 14px; 
    font-size: 15px; 
    line-height: normal; 
    -webkit-border-radius: 5px; 
    -moz-border-radius: 5px; 
    border-radius: 5px; 
    width: 15vw; 
    height: 15vh; 
    display:block;
    opacity: .25;
  }