// n is number of stars required
@function multiple-box-shadow ($n) {
  $value: '#{random(4000)}px #{random(8000)}px #FFF';
  @for $i from 3 through $n {
    $value: '#{$value} , #{random(4000)}px #{random(8000)}px #FFF'; }

  @return unquote($value); }

@function generate-shooting-star ($n) {
  $value: '#{random(3000)}px #{random(3000)}px #FFF';
  @for $i from 1 through $n {
    $value: '#{$value} , #{random(3000)}px #{random(3000)}px #FFF'; }

  @return unquote($value); }


@function generate-still-star ($n) {
  $value: '#{random(100)}vw #{random(100)}vh #FFF';
  @for $i from 1 through $n {
    $value: '#{$value} , #{random(100)}vw #{random(100)}vh #FFF'; }

  @return unquote($value); }

$shadows-small:  multiple-box-shadow(6000);
$shadows-medium: multiple-box-shadow(2000);
$shadows-big:    multiple-box-shadow(50);
$shooting-star: multiple-box-shadow(24);
$shooting-star-bottom-right: generate-shooting-star(10);
$shooting-star-bottom-left: generate-shooting-star(10);
$shooting-star-top-right: generate-shooting-star(10);
$shooting-star-top-left: generate-shooting-star(10);
$shooting-star-still-1: generate-still-star(70);
$shooting-star-still-2: generate-still-star(20);
$shooting-star-still-3: generate-still-star(50);

.LandingPageStyle {
  height: 100vh;
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
  overflow: hidden; }


.Constellation-Button {
  opacity: 0;
  animation			: ConstellationButtonFadeIn 3s linear 7s 1 forwards; }

.TopMenuBar {
  animation			: ConstellationButtonFadeIn 2s linear 1s 1 forwards; }

.ToggleSidebarController {
  animation			: ConstellationButtonFadeIn 2s linear 1s 1 forwards; }

// .TutorialBackground
//   animation			: TutorailBackgroundFadeIn 2s linear 5s 1 forwards

.ConstellationBackground {
  animation     : ConstellationBackgroundFadeIn 7s linear 5s 1 forwards; }

// .Constellation-Button:hover
//   opacity: 1
//   animation			: ConstellationButtonFadeInHover 1s linear 1 forwards

// .Constellation-Button:not(:hover)
//   animation			: ConstellationButtonFadeTransparent 2s linear 1 forwards

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation			: animStar 1500s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small; } }

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation			: animStar 1000s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium; } }

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation			: animStar 500s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big; } }

#clear_stars1 {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  opacity: 0;
  animation			: clearStars1 3s linear 1;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small; } }

#clear_stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  opacity: 0;
  animation			: clearStars2 4s linear 1;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
    opacity: 0; } }

#clear_stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  opacity: 0;
  animation			: clearStars3 5s linear 1;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-big;
    opacity: 0; } }

#stillStart {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shooting-star;
  opacity: .5; }


#introShootingStars {
  width: 5px;
  height: 5px;
  background: transparent;
  box-shadow: $shooting-star;
  opacity: 0;
  animation			: introShootingStars 1000s linear 10s infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shooting-star; } }


#randomShootingStarBottomLeft {
  width: 5px;
  height: 5px;
  background: transparent;
  box-shadow: $shooting-star-bottom-left;
  opacity: 0;
  animation			: randomShootingStarBottomLeft 202s linear 68s infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shooting-star-bottom-left; } }


#randomShootingStarBottomRight {
  width: 5px;
  height: 5px;
  background: transparent;
  box-shadow: $shooting-star-bottom-right;
  opacity: 0;
  animation			: randomShootingStarBottomRight 173s linear 135s infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shooting-star-bottom-right; } }


#randomShootingStarTopLeft {
  width: 5px;
  height: 5px;
  background: transparent;
  box-shadow: $shooting-star-top-left;
  opacity: 0;
  animation			: randomShootingStarTopLeft 123s linear 241s infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shooting-star-top-left; } }


#randomShootingStarTopRight {
  width: 5px;
  height: 5px;
  background: transparent;
  box-shadow: $shooting-star-top-right;
  opacity: 0;
  animation			: randomShootingStarTopRight 93s linear 309s infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shooting-star-top-right; } }


#starStoppedSize1 {
  width: 2px;
  height: 2px;
  box-shadow: $shooting-star-still-1;
  opacity: .6; }


#starStoppedSize2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shooting-star-still-2;
  opacity: .25; }


#starStoppedSize3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shooting-star-still-3;
  opacity: .1; }


#title {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;

  color: #FFF;
  text-align: center;
  font-family: 'lato',sans-serif;
  font-weight: 300;
  letter-spacing: 10px;

  margin-top: -60px;
  padding-left: 10px;
  line-height: 1.15 !important;

  span {
    background: -webkit-linear-gradient(white, #38495a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 6vw; }

  subTitleText {
    background: -webkit-linear-gradient(white, #38495a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.5vw; }

  subIntroMessage {
    background: -webkit-linear-gradient(white, #38495a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5vw;
    line-height: 6 !important; } }

.title-fade-in {
  animation: fadeIn ease 7s;
  -webkit-animation: fadeIn ease 7s;
  -moz-animation: fadeIn ease 7s;
  -o-animation: fadeIn ease 7s;
  -ms-animation: fadeIn ease 7s; }

.subtitle-fade-in {
  opacity: 0;
  animation: fadeIn ease 12s;
  -webkit-animation: fadeIn ease 12s;
  -moz-animation: fadeIn ease 12s;
  -o-animation: fadeIn ease 12s;
  -ms-animation: fadeIn ease 12s;
  animation-delay: 3s;
  animation-fill-mode: forwards; }

.sub-intro-message-fade-in {
  opacity: 0;
  // animation: fadeIn ease 12s
  // -webkit-animation: fadeIn ease 12s
  // -moz-animation: fadeIn ease 12s
  // -o-animation: fadeIn ease 12s
  // -ms-animation: fadeIn ease 12s
  animation-delay: 7s;
  animation-fill-mode: forwards; }

.title-fade-out {
  animation: fadeOut ease 3s;
  -webkit-animation: fadeOut ease 3s;
  -moz-animation: fadeOut ease 3s;
  -o-animation: fadeOut ease 3s;
  -ms-animation: fadeOut ease 3s;
  animation-fill-mode: forwards; }

.subtitle-fade-out {
  opacity: 0;
  animation: fadeOut ease 2s;
  -webkit-animation: fadeOut ease 2s;
  -moz-animation: fadeOut ease 2s;
  -o-animation: fadeOut ease 2s;
  -ms-animation: fadeOut ease 2s;
  animation-fill-mode: forwards; }

.sub-intro-message-fade-out {
  opacity: 0;
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
  animation-fill-mode: forwards; }


//All Browswers Fade in and out keyframes (ie. google, firefox etc.)
@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }

@-o-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-ms-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }


@keyframes animStar {
  0% {
    transform: translateY(0px); }
  100% {
    transform: translateY(-20000px); } }

@keyframes ConstellationButtonFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes ConstellationButtonFadeInHover {
  0% {
    opacity: .5; }
  100% {
    opacity: 1; } }

@keyframes ConstellationBackgroundFadeIn {
  0% {
      background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%); }
  100% {
      background: radial-gradient(circle at right 2% top 10%, rgba(196, 189, 166, 0.884) .15%, #bebebee0 2.5%, rgba(255, 255, 255, 0.432) 7%, rgba(7, 38, 95, 0.6) 35%, rgba(0, 1, 3, 0.863) 100%),
      radial-gradient(circle at left 15% bottom 12%, rgba(0, 0, 0, 0.966) 10%, rgba(12, 12, 12, 0.925) 25%, rgba(8, 8, 8, 0.541) 55%, rgba(1, 8, 20, 0.6) 100%) {} } }

@keyframes TutorailBackgroundFadeIn {
  0% {
      background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%); }
  100% {
      background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%); } }

@keyframes ConstellationButtonFadeTransparent {
  0% {
    opacity: 1; }
  100% {
    opacity: .5; } }

@keyframes clearStars1 {
  0% {
    transform: translateY(0px);
    opacity: 1; }
  2% {
    transform: translateY(10px);
    opacity: 1; }
  4% {
    transform: translateY(20px);
    opacity: 1; }
  6% {
    transform: translateY(25px);
    opacity: 1; }
  8% {
    transform: translateY(20px);
    opacity: 1; }
  10% {
    transform: translateY(-30px);
    opacity: .5; }
  20% {
    transform: translateY(-200px);
    opacity: .4; }
  30% {
    transform: translateY(-1500px);
    opacity: 0; }
  100% {
    transform: translateY(-2000px);
    opacity: 0; } }


@keyframes clearStars2 {
  0% {
    transform: translateY(0px);
    opacity: 1; }
  2% {
    transform: translateY(10px);
    opacity: 1; }
  4% {
    transform: translateY(20px);
    opacity: 1; }
  6% {
    transform: translateY(25px);
    opacity: 1; }
  8% {
    transform: translateY(20px);
    opacity: 1; }
  10% {
    transform: translateY(-60px);
    opacity: .5; }
  20% {
    transform: translateY(-400px);
    opacity: .4; }
  30% {
    transform: translateY(-1500px);
    opacity: .0; }
  100% {
    transform: translateY(-2000px);
    opacity: 0; } }


@keyframes clearStars3 {
  0% {
    transform: translateY(0px);
    opacity: 1; }
  2% {
    transform: translateY(10px);
    opacity: 1; }
  4% {
    transform: translateY(20px);
    opacity: 1; }
  6% {
    transform: translateY(25px);
    opacity: 1; }
  8% {
    transform: translateY(20px);
    opacity: 1; }
  10% {
    transform: translateY(-100px);
    opacity: .5; }
  20% {
    transform: translateY(-1000px);
    opacity: .4; }
  30% {
    transform: translateY(-2800px);
    opacity: .3; }
  40% {
    transform: translateY(-3800px);
    opacity: .3; }
  50% {
    transform: translateY(-5800px);
    opacity: .3; }
  100% {
    transform: translateY(-7000px);
    opacity: 0; } }


@keyframes introShootingStars {
  from {
    opacity: .5;
    transform: translate(0px, 0px);
    width: 5px;
    height: 5px; }
  to {
    opacity: .5;
    transform: translate(-1000000px, 800000px);
    width: 1px;
    height: 1px; } }


@keyframes randomShootingStarBottomLeft {
  from {
    opacity: .5;
    transform: translate(0px, 0px);
    width: 5px;
    height: 5px; }
  to {
    opacity: .5;
    transform: translate(-200000px, 160000px);
    width: 1px;
    height: 1px; } }

@keyframes randomShootingStarBottomRight {
  from {
    opacity: .5;
    transform: translate(0px, 0px);
    width: 5px;
    height: 5px; }
  to {
    opacity: .5;
    transform: translate(150000px, 120000px);
    width: 1px;
    height: 1px; } }

@keyframes randomShootingStarTopLeft {
  from {
    opacity: .5;
    transform: translate(0px, 0px);
    width: 5px;
    height: 5px; }
  to {
    opacity: .5;
    transform: translate(-140000px, -120000px);
    width: 1px;
    height: 1px; } }

@keyframes randomShootingStarTopRight {
  from {
    opacity: .5;
    transform: translate(0px, 0px);
    width: 5px;
    height: 5px; }
  to {
    opacity: .5;
    transform: translate(140000px, -120000px);
    width: 1px;
    height: 1px; } }
